<!-- 发票列表页面 -->
<template>
	<div class="invoice" :style="{minHeight:$store.state.clientHeight}">
		<div class="page-box">
			<!-- ######################################################################################################################## -->
			<!-- 页面标题 开始 -->
			<div class="page-title clear">
				<div class="page-icon fl">
					<i class="iconfont iconfapiao"></i>
					<span>开具发票</span>
				</div>
				<div class="page-return fr" @click="$router.go(-1);">
					<i class="am iconfont iconzhiyuanfanhui3"></i>
					<span class="am">返回上一步</span>
				</div>
			</div>
      <!-- 页面标题 结束 -->
      <!-- ######################################################################################################################## -->

      <!-- ######################################################################################################################## -->
      <!-- 发票列表 开始 -->
			<div class="invoice-container">
				<div class="invoice-detail" v-for="(item,index) in listData" :key="index">
					<div class="invoice-title clear">
            <span class="fl" v-if="item.status == '0' || item.status == 10 || (item.status == '1' && !item.Pdfurl)">开票中</span>
            <span class="fl" v-else-if="item.status == '1' && item.Pdfurl">已开票</span>
            <span class="fl" v-else-if="item.status == '2'">注销原发票中</span>
            <span class="fl" v-else>发票异常</span>
						<div class="fr btn-box"  v-if="item.status == 1 && item.remark == 0 && item.Pdfurl">
							<a :href="item.Pdfurl" target="_blank">查看发票</a>
							<p v-if="item.status ==1 && item.remark == 0" @click="reSendEmail(item.id)">重发邮箱</p>
							<p v-if="item.status ==1 && item.remark == 0" @click="applyChange(item.id)">换开申请</p>
						</div>
						<!-- <div class="fr" v-if="item.status==2">系统处理中</div> -->
						<div class="fr" style="color: red;" v-if="item.status==2">当您收到负金额冲减发票后返回已支付订单重新申请发票</div>
					</div>
					<div class="invoice-content clear">
						<span>开票编号：{{item.Number}}</span>
						<span>发票类型：电子普通发票</span>
						<span>申请时间：{{item.Times}}</span>
						<span v-if="item.type == '1'">发票抬头：个人</span>
						<span v-else-if="item.type == '2'">发票抬头：公司</span>
						<span>开票金额：{{(parseFloat(item.SumMoney)).toFixed(2)}}元</span>
					</div>
					<div class="invoice-bottom">开票日期：{{item.Times}}</div>
				</div>
				<noData v-if="noData" noDataText="暂无发票"></noData>
			</div>
      <!-- 发票列表 结束 -->
      <!-- ######################################################################################################################## -->
		</div>
	</div>
</template>

<script>
	import noData from '@/components/noData.vue'
	let that;
	export default {
		components:{
			noData
		},
		data:()=>{
			return {
        orderNo: '',									// 订单号
        listData: [],									// 发票列表
				emailValue:'',								// 邮箱号
				noData:false,									// 暂无数据
			}
		},
		created() {
			that = this;
      this.orderNo = this.$route.query.order_no;		// 获取订单号
      this.queryListData();
      this.$store.commit('updateH',130);
    },
		methods:{
			// 点击重发邮箱
			reSendEmail:function (id) {
				this.$prompt('请输入邮箱', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
					inputErrorMessage: '邮箱格式不正确'
				}).then(({ value }) => {
					that.$http({
            isMaterial: true,
						url:'invoice/resend_email',
						data:{
							id:id,
							email:value
						}
					}).then(res => {
						that.$message.success('重发成功');
					}).catch(err => {
						console.error(err);
					})
				}).catch((err) => {
					console.error(err);
				});
			},
			// 点击换开申请
			applyChange:function (id) {
				this.$confirm('是否换开发票？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					that.$http({
            isMaterial: true,
						url:'invoice/refund',
						data:{
							id:id
						}
					}).then(res => {
						// console.log(res);
						that.$message.success('申请成功');
						that.queryListData();
					}).catch(err => {
						console.error(err);
					})
				}).catch((err) => {
					console.error(err);
				})
			},

      /**
       * ####################################################################################################
       * ## 查询发票列表
       * ####################################################################################################
       */
      queryListData: function() {
        // return
        that.$http({
          isMaterial: true,
          url: 'invoice/lists',
          method: 'GET',
          params: {
            order_no: this.orderNo
          }
        }).then(res => {
					if (res.result.length < 1) {
						that.noData = true;
					} else {
						that.noData = false;
						that.listData = res.result;
					}
        }).catch(err => {
          console.error(err);
        })
      }
		}
	}
</script>

<style scoped>
	.invoice{padding-top: 90px;min-height: 800px;}
	.invoice-container{width: 900px;margin: 30px auto 50px;padding: 60px 0;}
	.invoice-detail{width: 100%;margin-bottom: 20px;}
	.invoice-detail .invoice-title{width: 100%;background-color: #3f7ad2;height: 55px;padding: 0 20px;}
	.invoice-detail .invoice-title .fl{color: #FFFFFF;font-size: .18rem;line-height: 55px;}
	.invoice-detail .invoice-title .fr{width: 75%;text-align: right;color: #FFFFFF;font-size: .18rem;line-height: 55px;}
	.invoice-detail .invoice-title .fr p,.invoice-detail .invoice-title .fr a{display: inline-block;margin-left: 10px;width: 80px;height: 35px;color: #FFFFFF;cursor: pointer;line-height: 35px;border: 1px solid #FFFFFF;font-size: .16rem;text-align: center;background: #3f7ad2;background: linear-gradient(90deg,#3fa6d2,#3f7ad2);}
	.invoice-detail .invoice-content{border: 1px solid #EFEFEF;padding: 30px 20px;}
	.invoice-detail .invoice-content span{float: left;width: 50%;}
	.invoice-detail .invoice-bottom{border: 1px solid #EFEFEF;border-top: none;text-align: right;padding: 0 20px;line-height: 3;}
  .el-pagination {
    text-align: center;
    margin-top: 30px;
  }

</style>
